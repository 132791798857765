$.fn.serializeObject = function()
{
    var o = {};
    var a = this.serializeArray();
    $.each(a, function() {
        if (o[this.name]) {
            if (!o[this.name].push) {
                o[this.name] = [o[this.name]];
            }
            o[this.name].push(this.value || '');
        } else {
            o[this.name] = this.value || '';
        }
    });
    return o;
};

$.fn.keyupDelay = function(cb, delay) {
    if (delay == null) {
        delay = 200;
    }

    var timer = 0;
    return $(this).on('keyup', function() {
        clearTimeout(timer);
        timer = setTimeout(cb, delay);
    });
}

$(document).ready(function() {

    if ($.isFunction(jQuery.fn.slidesjs)) {
        if ($("#slides a").length > 1) {
            $("#slides").slidesjs({
                width: 1140,
                height: 300,
                navigation: {
                    active: false
                },
                pagination: {
                    active: false
                },
                play: {
                    active: false,
                    swap: false,
                    interval: 3000,
                    effect: "fade",
                    auto: true,
                    pauseOnHover: true
                },
                effect: {
                    fade: {
                        speed: 600
                    }
                }
            });
        }
    }

    if (typeof (_i_bg_pos) != 'undefined') {
        $("body").removeClass('responsive').addClass('bbg');
    }
    else {
        $("#banner20").html('<script async src="//pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"></script><ins class="adsbygoogle googleMidBanner" style="display:block" data-ad-client="ca-pub-1989602340116394" data-ad-slot="9502293863" data-ad-format="auto"></ins><script>(adsbygoogle = window.adsbygoogle || []).push({});</script>');
    }

    /**
    * Banner
    */
   /*
    if($('#searchBanner').length > 0) {
        var bannerPos = $('#searchBanner').offset().top;
        var footerPos = $('#mainFoot').offset().top - 560;

        window.onscroll = function() {
            var pageOffset = window.pageYOffset;
            if (pageOffset >= footerPos) {
                $('#searchBanner').css({position: 'fixed', top: '', bottom: '220px'});
            }
            else if (pageOffset >= bannerPos) {
                $('#searchBanner').css({position: 'fixed', top: '55px'});
            }
            else {
                $('#searchBanner').css({position: '', right: '', top: ''});
            }
        }
    }
    */


    // brandline
    var touchSuport = touchSupported();
    if(touchSuport == true) {
        $("body").addClass('mobile');   
    }


    $('#brandline .loginBtn').click(function() {
        $(this).parents('#brandlineWrapper').find('#blLogin').toggle();
        return false;
    });

    $('#displayName .user').click(function() {
        //return false;
    });

    $('#displayName .user, .userMenu').hover(function() {
        $('.userMenu').toggle();
    });

    /**
     * Cookies
     */
    var cookieName = 'salomonCookie';

    function checkCookies(cookieName) {
        if (typeof ($.cookie(cookieName)) === 'undefined') {
            $("#cookieInfo").show();
            setCookie(0);
        }
    }
    
    function getCookieVal() {
        var cookie = [];
        if (typeof ($.cookie(cookieName)) != 'undefined') {
            cookie = $.cookie(cookieName).split('-');
            cookie[0] = parseInt(cookie[0]);
        }

        return cookie;
    }

    function setCookie(settings) {
        var clientId = 0;

        if (settings & 0x1)
            clientId = getClientId();

        var cookieVal = settings + '-' + clientId;

        $.cookie(cookieName, cookieVal, {expires: 365, path: '/'});
    }

    function getClientId() {
        var cookieVal = getCookieVal();

        if (cookieVal.length == 2 && cookieVal[1] != 0) {
            clientId = cookieVal[1];
        } else if (typeof ($.cookie('_ga')) != 'undefined') {
            clientId = $.base64.encode($.cookie('_ga'));
        } else {
            clientId = $.base64.encode(
                    $.md5([
                        navigator.userAgent,
                        [screen.height, screen.width, screen.colorDepth].join("x"),
                        (new Date()).getTimezoneOffset(),
                        !!window.sessionStorage,
                        !!window.localStorage,
                        $.map(navigator.plugins, function(p) {
                            return [
                                p.name,
                                p.description,
                                $.map(p, function(mt) {
                                    return [mt.type, mt.suffixes].join("~");
                                }).join(",")
                            ].join("::");
                        }).join(";")
                    ].join("###"))
                    );
        }

        return clientId;
    }

    $('#cookieInfo .accept').click(function() { // accept
        setCookie(0);
        $('#cookieInfo').slideUp();

        return false;
    });

    $('#cookieForm input').click(function() { // set cookie
        $(this).parents('.option').find('.checked').removeClass('checked');
        $(this).parent().find('label').addClass('checked');

        switch ($(this).attr('id')) {
            case('type-0_off'): // disable analytics
                $.removeCookie('_ga', {path: '/', domain: '.salomon.si'});
                break;
            case('type-1_off'): // disable iprom
                disableIpromCookie();
                //_ipromOptout();
                break;
            case('type-1_on'): // enable iprom
                enableIpromCookie();
                break;
        }

        var checked = $(this).parents('form').find('input:checked');

        var cookieVal = 0;
        for (i = 0; i < checked.length; i++)
            cookieVal += parseInt($(checked[i]).val());

        if (cookieVal >= 0 && cookieVal <= 3)
            setCookie(cookieVal);
    });

    if ($('#cookieForm input').length) { // populate
        var types = [0x1, 0x2];

        var cookieValue = getCookieVal();

        for (i = 0; i < types.length; i++) {
            if (cookieValue[0] & types[i]) {
                $('.type-' + i + '_off').find('input').prop('checked', true);
                $('.type-' + i + '_off').find('label').addClass('checked');
            } else
                $('.type-' + i + '_on').find('label').addClass('checked');
        }
    }

    checkCookies(cookieName);

    // Tabs
    $(".tabs header a").click(function() {
        tabName = $(this).attr('href').substring(1);

        $(this).parents(".tabs").find("header .active").removeClass('active');
        $(this).parent().addClass('active');

        $(this).parents(".tabs").find("article").addClass('hide');
        $(this).parents(".tabs").find('article[sal-tab="' + tabName + '"]').removeClass('hide');

        return false;
    });

    $('.leftTabs a').click(function(e) {
        e.preventDefault();
        $('.leftTabs a').removeClass('active');
        $('.tab-pane').hide();
        $($(this).attr('href')).show();
        $(this).addClass('active');
    });

    $(".checkAll").click(function() {
        $('.checkItem').prop('checked', $(this).is(':checked'));
    });

    if ($.isFunction(jQuery.fn.redactor)) {
        $('.wysiwyg').redactor(
                {
                    lang: 'sl',
                    emptyHtml: "<p></p>",
                    buttons: ['html', '|', 'formatting', '|', 'bold', 'italic', 'deleted', '|',
                        'unorderedlist', 'orderedlist', 'outdent', 'indent', '|',
                        'table', 'link', '|',
                        'fontcolor', 'backcolor', '|', 'alignment', '|', 'horizontalrule']
                }
        );
    }

    $('.dropdown-toggle').dropdown();
    $('.labelTooltip').tooltip();

    $("#login-company").click(function() {
        $("#fieldset-publish").toggle();
        $("#fieldset-internal").toggle();
        $("#tshirt").toggle();
    });

    if ($("#login-company").is(':checked')) {
        $("#fieldset-publish").show();
        $("#fieldset-internal").show();
        $("#tshirt").hide();
    }

    if ($('#loginPopup').length) {
        $('.loginReq').click(function(e) {
            e.preventDefault();
            $('#loginPopup').modal('show');
        });
    }

    $("#resetCookies").click(function(f) {
        f.preventDefault();
        $.cookie("cc_cookie_accept", null, {
            path: '/'
        });
        $.cookie("cc_cookie_decline", null, {
            path: '/'
        });

        location.reload();
    });

    $(".confirm").click(function() {
        return confirm("Ste prepričani?");
    });

    $('.alert .close').click(function() {
        $(this).parents('.alert').slideUp(400, function() {
            $(this).detach();
        });
    });

    $("#placeBid").click(function(e) {

        if ( $('#loginPopup').length ) {
            return;
        }

        if ( $('#newPriceInput').length ) {
            var val = $('#newPriceInput').val();

            if ( val == '')
                return;

            $('#bidCurPrice').val( val );
            $('#bidPrice').html( val + ' €' );
        }

        if ( $("#mobileConfirmDialog").length === 1 ) {
            e.preventDefault();
            $("#mobileConfirmDialog").modal('show');
        }
        else {
            $("#placeBidModal").modal('show');
        }

    });

    $("#mobileSendcode").click(function(e) {
        e.preventDefault();

        btn = $(this);
        btnLoading('loading', btn);

        $.post('/user/member/add-mobile', {mobile: $('#mobile').val()}, function(result) {
            btnLoading('reset', btn);
            if ( result === '0') {
                $("#mobileConfirmDialog .alert").hide();
                $("#mobileConfirmDialog").modal('hide');
                $("#mobileConfirmCodeDialog").modal('show');
            }
            else {
                $("#mobileConfirmDialog .alert").show();
            }
        });
    });

    $("#mobileConfirmCode").click(function(e) {
        e.preventDefault();

        btn = $(this);
        btnLoading('loading', btn);
        $.post('/user/member/confirm-mobile', {code: $('#mobileCode').val()}, function(result) {
            btnLoading('reset', btn);
            if ( result === '0') {
                $("#mobileConfirmCodeDialog .alert").hide();
                $("#mobileConfirmCodeDialog").modal('hide');
                $("#placeBidModal").modal('show');
            }
            else {
                $("#mobileConfirmCodeDialog .alert").show();
            }
        }); 
    });

    /**
    * Login and Register
    */
    if($('#submitLoginPopup').length > 0) {
        var loginDialog = $('#submitLoginPopup');
        var loginForm = loginDialog.find('#Registracijauporabnka');
        var registrationForm = loginDialog.find('#RegisterCompany');

        $('#nextStep a').click(function(){
            $.post('/advert/submit/st', {stId: $(this).data('stid'), ev: 3}, function() { });
            loginDialog.modal('show');
            return false;
        });

        loginForm.find('#login').click(function() {
            handleLoginForm(loginForm);
            return false;
        });

        function handleLoginForm(loginForm) {
            $.post('/user/index/login', loginForm.serialize(), function(data) {
                var IS_JSON = true;
                try {
                    var data = $.parseJSON(data);
                }
                catch(err) {
                    IS_JSON = false;
                }

                if(IS_JSON)  { 
                    if('succ' in data) { // redirect
                        var url = $('#nextStep a').attr('href');
                        window.location = url;
                    } else if('error' in data) { // login failed
                        loginForm.find('#password').val('');
                        alert(data.error);
                    }
                } else { // form data not valid
                    loginForm.html(data);

                    loginForm.find('#login').click(function() {
                        handleLoginForm(loginForm);
                        return false;
                    });
                }
            });
        }

        registrationForm.find('#profileSubmit').click(function() {
            handleRegistrationForm(registrationForm);
            return false;
        });

        function handleRegistrationForm(registrationForm) {
            $.post('/user/index/register', registrationForm.serialize(), function(data) {
                var IS_JSON = true;
                try {
                    var data = $.parseJSON(data);
                }
                catch(err) {
                    IS_JSON = false;
                }

                if(IS_JSON)  { 
                    if('succ' in data) { // redirect
                        var url = $('#nextStep a').attr('href');
                        window.location = url;
                    }
                } else { // form data not valid
                    registrationForm.html(data);

                    registrationForm.find('#profileSubmit').click(function() {
                        handleRegistrationForm(registrationForm);
                        return false;
                    });
                }
            });
        }
    }


    /**
     * Slider
     */
    var isRunning = false;
    var curWindowWidth = $(window).width();
    var resizeReset = false;

    $('.sliderHeader .left, .sliderHeader .right').click(function() {
        if (isRunning == true)
            return false;

        if (resizeReset == true) {
            $('.slider > div').animate({marginLeft: "0px"}, 800);
            resizeReset = false;

            return false;
        }

        isRunning = true;

        var container = $(this).parents('article');
        var slideDiv = container.find('.slider > div');

        var elementCount = container.find('section').length;
        var sectionWidth = container.find('section:first').width();
        var width = parseInt(elementCount * sectionWidth);

        var curMargin = parseInt(slideDiv.css('margin-left'));
        var newMargin = 0;
        var speed = 800;

        if ($(this).hasClass('left')) { //left
            speed = 400;
            if (curMargin > ((width - sectionWidth) * -1)) {
                newMargin = curMargin - sectionWidth;
            }
        } else {
            speed = 400;

            if (curMargin < 0)
                newMargin = curMargin + sectionWidth;
            else if (curMargin == 0)
                newMargin = -sectionWidth * (elementCount - 1);
        }

        slideDiv.animate({marginLeft: newMargin + "px"}, speed, function() {
            isRunning = false;
        });

        return false;

    });

    if ($('.slider').length > 0) {
        var resizeTimer;

        $(window).resize(function() {
            clearTimeout(resizeTimer);
            resizeTimer = setTimeout(setResizeCheck, 100);
        });

        function setResizeCheck() {
            var resizeWidth = $(window).width();
            var resetLimit = 1179;

            if ((resizeWidth > resetLimit && curWindowWidth < resetLimit) || (resizeWidth < resetLimit && curWindowWidth > resetLimit)) {
                curWindowWidth = resizeWidth;
                resizeReset = true;
            }
        }
    }

    /*$(".slider .up, .slider .down").click(function() {
     var firstArticle = $(this).parent().find("article:first");
     var elementCount = $(this).parent().find("article").length;
     var height = parseInt(firstArticle.outerHeight());
     var containerHeight = $(this).parent().find("div:first").height();
     var curMargin = parseInt(firstArticle.css('margin-top'));
     var elementOnPage = Math.ceil(containerHeight / height);
     speed = 800;
     
     if ($(this).hasClass('up')) { //up
     if (curMargin >= 0) {
     speed = 400;
     newMargin = curMargin - (height * (elementCount - elementOnPage));
     } else {
     newMargin = curMargin + (height * elementOnPage);
     }
     }
     else {
     if (curMargin <= ((height * (elementCount - elementOnPage)) * -1)) {
     speed = 400;
     newMargin = 0;
     }
     else {
     newMargin = curMargin - (height * elementOnPage);
     }
     }
     
     firstArticle.animate({marginTop: newMargin + "px"}, speed);
     
     return false;
     });*/

    $('#submitForm input, #submitForm select, #submitForm checkbox, #submitForm radio, #submitForm fieldset').change(function() {
        if ($(this).attr('sal-print') == '1')
            generateText();
    });

    $('#descriptionPrint-printPrefix').keyup(function() {
        generateText();
    });

    $(".dropdown-menu label").bind('click', function(e) {
        e.stopPropagation()
    });

    $("#nagradnaSodeluj").click(function(e) {
        e.preventDefault();

        $.get("/advert/submit/nagradnaigra", {'code': $(this).attr('sal-code')},
        function(result) {
            if (result === "1") {
                $("#nagradnaSodeluj").parent('div').removeClass('advertRemoved').addClass('successEdit');
            }
            else if (result === "2") {
                $("#nagradnaSodeluj").parent('div').removeClass('successEdit').addClass('advertRemoved');
            }
        });
    });

    $("#activeFilters .dropdown-toggle").click(function() {
        awidth = $(this).outerWidth();
        dwidth = $(this).next().outerWidth();

        if (dwidth < awidth)
            $(this).next().css('width', (awidth - 2) + 'px');
    });


    $("#activeFilters button.basicFilter").click(function() {
        var elem = $(this).parents('.dropdown-menu').find('input:checked');
        var fil = '';

        var sep = '';
        for (i = 0; i < elem.length; i++) {
            fil += sep + $(elem[i]).val();
            sep = '_';
        }
        ;

        var curAttr = $(this).attr('sal-attr');

        var ele = $("#catSearchForm").find('input[name="' + curAttr + '"]');
        if (ele.length === 1) {
            ele.val(fil);
        }
        else {
            $('<input type="hidden" value="' + fil + '" name="' + curAttr + '">').appendTo("#catSearchForm");
        }

        $("#catSearchForm").submit();
    });

    $("#activeFilters button.attrFilter").click(function() {
        aF = $('#catSearchForm input[name="filters"]').val().split('x');
        var fil = '';
        var elem = $(this).parents('.dropdown-menu').find('input:checked');

        var sep = '';
        for (i = 0; i < elem.length; i++) {
            fil += sep + $(elem[i]).val();
            sep = '_';
        }
        ;

        var curAttr = $(this).attr('sal-attr');

        var newFilter = '';
        sep = '';

        for (i = 0; i < aF.length; i++) {
            filPar = aF[i].split('-');

            newFilter += sep + filPar[0] + '-';

            if (filPar[0] === curAttr) {
                newFilter += fil;
            }
            else {
                newFilter += filPar[1];
            }

            sep = 'x';
        }

        $('#catSearchForm input[name="filters"]').val(newFilter);
        $('#catSearchForm').submit();
    });

    $("#searchByTax").click(function(e) {
        e.preventDefault();
        btn = $(this);
        btnLoading('loading', btn);

        $.getJSON('/user/index/taxajax',
                {'tax': $('#publish-taxId').val()}
        ).done(function(data) {
            if (data != 0) {
                $("#publish-identityId").val(data.identityId);
                $("#publish-coName").val(data.coName);
                $("#publish-address").val(data.address);

                tax = data.taxNumber;
                if (data.zavezanecZaDDV == "1")
                    tax = 'SI' + tax;

                $("#publish-taxId").val(tax);

                if ($("#publish-hideMap").is(':checked'))
                    $("#publish-hideMap").click();
                else {
                    $("#publish-hideMap").click().click();
                }
            }
            btnLoading('reset', btn);
        });
    });

    // autocomplete   
    /*
     $('#mainSearchText').typeahead({
     items: 8,
     minLength: 2,
     source: function(query, process) {
     return $.get('/ac.php', {q: query}, function(data) {
     return process(data);
     });
     }
     });    
     //*/

    // more options
    var moreOptionsForm = null;

    $('.allOptions').click(function() {
        moreOptionsForm = $("#catSearchForm").serializeObject();
        if (moreOptionsForm.filters === undefined)
            moreOptionsForm.filters = '';

        $('#attrTab-' + $(this).attr('sal-attrId')).tab('show');
    });

    $('#moreValuesSubmit').click(function() {
        filters = createMoreValuesFilter(moreOptionsForm.filters, -1);

        var ele = $('#catSearchForm input[name="filters"]');
        if (ele.length === 1) {
            ele.val(filters);
        }
        else {
            $('<input type="hidden" value="' + filters + '" name="filters">').appendTo("#catSearchForm");
        }

        $('#catSearchForm').submit();
    });

    $('#moreFilterValues a[data-toggle="tab"]').on('shown', function(e) {
        var urlAction = $("#catSearchForm").attr('action');
        var attrId = $(this).attr('sal-attrId');

        moreOptionsForm.mvAtt = attrId;

        var activeFilters = new Array();
        var i = 0;
        if (moreOptionsForm.filters != "") {
            af = moreOptionsForm.filters.split('x');
            for (f in af) {
                var v = af[f].split('-');
                v = v[1].split('_');
                for (val in v) {
                    activeFilters[i++] = parseInt(v[val]);
                }
            }
        }

        moreOptionsForm.filters = createMoreValuesFilter(moreOptionsForm.filters, attrId);

        var currentTab = $("#attrMoreVal-" + attrId);
        var currentTabContent = currentTab.find("#attrMoreValContent-" + attrId);

        //clear search input
        currentTab.find('.attrSearch').val('');

        currentTabContent.html('<i class="load fa fa-spinner fa-spin fa-3x"></i>');
        $.getJSON(urlAction, moreOptionsForm).done(function(data) {
            var check = '';
            for (i = 0; i < data.length; i++) {
                check += '<label><input type="checkbox" value="' + data[i]['id'] + '"';
                if ($.inArray(data[i]['id'], activeFilters) != -1)
                    check += ' checked';

                check += '>' + data[i]['name'] + '</label>';
            }
            currentTabContent.html(check);
        });
    });

    function createMoreValuesFilter(curFilters, attrId) {
        aF = curFilters.split('x');

        var filter = '';
        var fsep = '';
        $("#moreFilterValues .tab-pane div").each(function() {
            aId = $(this).attr('sal-attrid');
            if (aId != attrId && $(this).find(':checked').length > 0) {
                var sel = '';
                var sep = '';

                $(this).find(':checked').each(function() {
                    sel += sep + $(this).val();
                    sep = '_';
                });

                filter += fsep + aId + $(this).attr('sal-attrtyp') + '-' + sel;
                fsep = 'x';
            }
        });

        return filter;
    }

    $("#avtodeliZnamka, #avtodeliLetnik").change(function() {
        var z = $("#avtodeliZnamka").val();
        var l = $("#avtodeliLetnik").val();
        $("#avtodeliVozila").html('');

        if (z !== '-1' && l !== '-1') {
            $("#avtodeliModel").attr('disabled', 'disabled');
            $("#avtodeliModel").load(
                    '/ogl/landing-pages/load-avtodelimodel',
                    {znamkaId: z, letnik: l},
            function() {
                $("#avtodeliModel").removeAttr('disabled');
            }
            );
        }

    });

    $("#avtodeliModel").change(function() {
        var m = $(this).val();
        var l = $("#avtodeliLetnik").val();

        if (m !== "-1") {
            $("#avtodeliVozila").html('<i class="fa fa-spinner fa-spin fa-2x"></i>');
            $("#avtodeliVozila").load('/ogl/landing-pages/avtodeli-loadvozilo', {voziloId: $(this).val(), letnik: l});
        }
    });

    $("#vecSearch .addnew").click(function() {
        $(this).hide();
        $("#" + $(this).attr('href').substring(1)).show();

        return false;
    });

    $(".specCars .brand").change(function() {
        val = $(this).val();
        model = $(this).parents('.controls').find('.model');

        if (model.length == 0)
            model = $('.model');

        model.attr('disabled', 'disabled');

        if (val === '') {
            model.html('<option value=""></option>');
            model.removeAttr('disabled');
        }
        else {
            $.getJSON("/ogl/search/get-child-values",
                    {
                        valId: $(this).val(),
                        child: 396
                    },
            function(j) {
                options = '<option valuue=""></option>';
                for (var i = 0; i < j.length; i++) {
                    if (j[i].value == "-1")
                        options = false;
                    else
                        options += '<option value="' + j[i].value + '">' + j[i].display + '</option>';

                }

                model.html(options);
                model.removeAttr('disabled');
            });
        }
    });

    // tourism landing page
    var tourismLanding = $('#tourismLanding');
    if (tourismLanding.length > 0) {

        // vacation summer/winter
        tourismLanding.find("#summerTourism, #winterTourism, #otherTourism").click(function() {
            var div = $(this).parents('#vacation');
            div.find('article').hide();

            var article = '';
            switch ($(this).attr('id')) {
                case('summerTourism'):
                    var article = div.find('article.summer');
                    break;
                case('winterTourism'):
                    var article = div.find('article.winter');
                    break;
                case('otherTourism'):
                    var article = div.find('article.other');
                    break;
            }

            if (article != '') {
                article.show();
                article.find('form')[0].reset();
                article.find('form .att-child').parent().parent().hide();
            }
        });

        $("#summerTourism").click();

        // parent child elements
        tourismLanding.find('select.att-parent').change(function() {
            var childId = $(this).data('child-id');
            var childEl = tourismLanding.find('select[data-attr-id="' + childId + '"]');
            var childElContainer = childEl.parents('.control-group');

            childEl.empty();
            childEl.append($('<option></option>').attr('value', '-1').text('---'));
            childElContainer.hide();

            var val = $(this).val();
            if (val in attr[childId]) {
                var attrVal = attr[childId][val];
                for (key in attrVal) {
                    childEl.append($('<option></option>').attr('value', key).text(attrVal[key]));
                }

                childElContainer.show();
            }
        });

        tourismLanding.find('select.att-parent').change();
    }

    $("#sortBy").change(function() {
        var ele = $("#catSearchForm").find('input[name="sortBy"]');
        if (ele.length === 1) {
            ele.val($(this).val());
        }
        else {
            $('<input type="hidden" value="' + $(this).val() + '" name="sortBy">').appendTo("#catSearchForm");
        }

        $("#catSearchForm").submit();
    });

    $("#contactId").change(function() {
        var ele = $("#catSearchForm").find('input[name="contactId"]');
        if (ele.length === 1) {
            ele.val($(this).val());
        }
        else {
            $('<input type="hidden" value="' + $(this).val() + '" name="contactId">').appendTo("#catSearchForm");
        }

        $("#catSearchForm").submit();
    });

    $("#onPage").change(function() {
        var ele = $("#catSearchForm").find('input[name="onPage"]');
        if (ele.length === 1) {
            ele.val($(this).val());
        }
        else {
            $('<input type="hidden" value="' + $(this).val() + '" name="onPage">').appendTo("#catSearchForm");
        }

        $("#catSearchForm").submit();
    });

    /*******
     Gallery
     *******/
    $("#smallImg").click(function() {
        var act = $(".thumbsList a.active");
        var imgCount = $(".thumbsList li").length;
        var nxtImg = $(".thumbsList a").index(act) + 1;

        if ((imgCount - 1) < nxtImg)
            nxtImg = 0;

        $(".thumbsList a")[nxtImg].click();
        return false;
    });

    $("#craftsmanGallery .thumbsList a").click(function(e) {
        $("#showBig").click();
    });

    $("#advGallery .prev, #advGallery .next").click(function() {
        //thumbsList
        var thumbL = $("#advGallery .thumbsList ul");
        var curMargin = parseInt(thumbL.css('margin-left'));

        var mL = 69 * 2;
        var imgCount = $("#advGallery .thumbsList li").length;
        var limit = Math.ceil((imgCount - 5) / 2) * mL * -1;

        if ($(this).hasClass("next")) {
            newMargin = curMargin - mL;

            if (newMargin < limit)
                return false;
        } else {
            newMargin = curMargin + mL;
            if (newMargin > 0)
                return false;
        }

        thumbL.animate({marginLeft: newMargin + "px"}, 400);
        return false;
    });

    $("#advGallery .thumbsList a").click(function() {
        $("#advGallery .bigImg img").attr('src', $(this).attr('href'));
        $("#advGallery .thumbsList a.active").removeClass('active');
        $(this).addClass('active');
        return false;
    });

    $("#advGallery .thumbsList .notice").click(function() {
        $(this).parents('li').find('a').click();
    });

    if ($("#showBig").length > 0) {
        $("#showBig").click(function() {
            if ($('#galleryBigImg .modal-body .bigImg').length == 0) // prepare gallery
                bigGalleryInit();

            var index = parseInt($('#advGallery .thumbsList li a.active').parent().index());
            $('#galleryBigImg .thumbsList li:eq(' + index + ') a').click();

            $('#galleryBigImg').modal('show');

            return false;
        });

        document.onkeydown = function(e) {
            if ($('#galleryBigImg:visible').length == 0)
                return;

            if (e.keyCode == 27) { // esc
                $('#galleryBigImg').modal('hide');
            } else if (e.keyCode == 37) { // back
                changeImg('prev');
            } else if (e.keyCode == 39) { // next
                changeImg('next');
            }
        };

        function changeImg(action) {
            var thumbsList = $('#galleryBigImg .thumbsList');
            var act = thumbsList.find('a.active');
            var imgCount = thumbsList.find('li').length;

            if (action == 'next') { // next
                var nxtImg = thumbsList.find('a').index(act) + 1;

                if ((imgCount - 1) < nxtImg)
                    nxtImg = 0;

            } else { // prev
                var nxtImg = thumbsList.find('a').index(act) - 1;

                if (nxtImg < 0)
                    nxtImg = imgCount - 1;
            }

            $('#galleryBigImg .thumbsList a')[nxtImg].click();
            return false;
        }
    }

    // Advert contact tabs
    var loaded = false;
    $("#selLocation").click(function() {
        if (loaded == true)
            return false;

        var geoLat = $("#geoLat").html();
        var geoLng = $("#geoLng").html();
        if (geoLat.length == 0 || geoLng.length == 0)
            return false;

        geocoder = new google.maps.Geocoder();

        var latlng = new google.maps.LatLng(parseFloat(geoLat), parseFloat(geoLng));
        var mapOptions = {
            zoom: 15,
            center: latlng,
            mapTypeId: google.maps.MapTypeId.ROADMAP
        }
        var map = new google.maps.Map(document.getElementById('map-canvas'), mapOptions);

        var marker = new google.maps.Marker({
            map: map,
            position: latlng
        });

        loaded = true;
    });

    if ($("#showMap").length > 0) {
        $("#selLocation").click();
    }

    if (!$('#loginPopup').length) { // only for registered members
        $("#advertSave").click(function(e) {
            btn = $(this);
            btnLoading('loading', btn);

            e.preventDefault();

            $.getJSON('/user/favourite/advert-save', {'code': $(this).attr('sal-advertCode')})
                    .done(function(data) {
                        var statusClass = (data.status == 1) ? 'warning' : 'success';
                        $('#flashMsg').addClass(statusClass).find('span').text(data.msg);
                        $('#flashMsg').show();

                        $('body').animate({scrollTop: 0}, 400);
                        btnLoading('reset', btn);
                    }
                    );
        });

        $("#sellerSave").click(function(e) {
            btn = $(this);
            btnLoading('loading', btn);

            e.preventDefault();

            $.getJSON('/user/favourite/seller-save', {'sellerId': $(this).attr('sal-sellerId')})
                    .done(function(data) {
                        var statusClass = (data.status == 1) ? 'warning' : 'success';
                        $('#flashMsg').addClass(statusClass).find('span').text(data.msg);
                        $('#flashMsg').show();

                        $('body').animate({scrollTop: 0}, 400);
                        btnLoading('reset', btn);
                    }
                    );
        });

        $('#improperAdvert').click(function() {
            $('#improperAdvertDialog .notice').hide();
            $('#improperAdvertDialog').modal('show');
            $('div.form').show();
            $('#improperPost').show();
            return false;
        });

        $('#improperAdvertDialog .save').click(function(){
            var form = $(this).parents('form');
            var postVal = form.serialize();
            var noticeDiv = form.find('.notice');
            $.post('/advert/index/improper-advert', postVal, function(data) {
                var data = $.parseJSON(data);
                if('succ' in data) { // ok
                    noticeDiv.removeClass('error');
                    noticeDiv.find('span').text(data.succ);
                    noticeDiv.show();
                    form[0].reset();
                    $('div.form').hide();
                    $('#improperPost').hide();

                } else if('error' in data) { // error
                    noticeDiv.addClass('error');
                    noticeDiv.find('span').text(data.error);
                    noticeDiv.show();
                } else {
                    noticeDiv.addClass('error');
                    noticeDiv.find('span').text('Prišlo je do napake.');
                    noticeDiv.show();
                }
            });

            return false;
        });

        var reasonTextarea = $('#improperAdvertDialog textarea');
        $('#improperAdvertDialog input[name="reason"]').click(function(){
            if($(this).val() != 3)
                reasonTextarea.css({"visibility":"hidden"});
            else
                reasonTextarea.css({"visibility":"visible"});
        });
    }

    if($('#mainSearchText, #searchText, #quickSearchText').length > 0) {
        var suggestResUl = $('.searchRes ul');
        var catsResUl = $('.catList ul');
        var searchSuggestDiv = $('#searchSuggest');

        $('#mainSearchText, #searchText, #quickSearchText').keydown(function(e){
            var key = e.which;
            var suggestResLi = $('.searchRes ul li');
            var suggestResLiCount = suggestResLi.length;

            if(suggestResLiCount > 0 && (key == 38 || key == 40)) { // key up/down
                var currSel = suggestResUl.find('li.active');
                var newPos = 0;

                var posChange = 0; // first pos
                if(currSel.length > 0) {
                    var posChange = (key == 40) ? 1 : -1; // up(40) : down(38)
                    var newPos = currSel.index() + posChange; // oldPos - change
                } 
                
                if(newPos > (suggestResLiCount - 1)) { // first
                    newPos = 0;
                } 
                else if(newPos < 0) { // last
                  newPos = suggestResLiCount - 1;  
                }

                //update
                var newSelElement = suggestResLi.eq(newPos);
                suggestResLi.removeClass('active');
                newSelElement.addClass('active');

                e.preventDefault();
            } 
        });
    
        var oldSearchVal = '';
        $('#mainSearchText, #searchText, #quickSearchText').keyup(function(e) {

            if(e.which == 27) // esc
                searchSuggestDiv.hide();
            
            if(oldSearchVal != '' && oldSearchVal == $(this).val()) // ignore id search term didn't change
                return false;
            
            oldSearchVal = $(this).val();
            suggestResUl.find('li').remove();
            catsResUl.find('li').remove();

            if($(this).val().length >= 3) {
                var suggestRes = $.get('/ac.php', {q: $(this).val()});

                suggestRes.done(function(data) {
                    if(data.suggest != null) {
                        searchSuggestDiv.show();
                        // suggests
                        var html = '';
                        var tmpCount = data.suggest.length - 1;
                        for(i=0; i<=tmpCount; i++) {
                            html += '<li><a href="/oglasi/?q=' + data.suggest[i] + '">' + data.suggest[i] + '</a></li>';
                        }
                        suggestResUl.append(html);

                        // cats
                        var html = '';
                        var tmpCount = data.cats.length - 1;
                        for(i=0; i<=tmpCount; i++) {
                            html += '<li><a href="/oglasi/' + data.cats[i]['url'] + '/?q=' + oldSearchVal + '*">' + data.cats[i]['name'] + ' <span>(' + data.cats[i]['count'] + ')</span></a></li>';
                        }
                        catsResUl.append(html);
                    } 
                    else { // no results
                        searchSuggestDiv.hide();
                    }
                });
            } else {
                searchSuggestDiv.hide();
            }
        });

        $('#mainSearchText, #searchText, #quickSearchText').keypress(function(e) { // search term submit
            if(e.which == 13) {
                var selPhrase = suggestResUl.find('li.active a');
                if(selPhrase.length > 0) {
                    e.preventDefault();
                    window.location.href = selPhrase.attr('href');
                    return false;
                }
            }
        });

        // hide suggest div
        $('html').click(function() {
            searchSuggestDiv.hide();
        });
        $('#searchSuggest').click(function(e){
            e.stopPropagation();
        });
    }

    /*************
     Save search
     *************/
    if ($('#saveSearchModal').length > 0) {
        var dates = $("#dateStart, #dateEnd").datepicker({
            defaultDate: "+1w",
            regional: "sl",
            buttonImageOnly: false,
            buttonClass: 'hide'
        });

        $("#saveSearchModal .replaceSearch").click(function() {
            if ($("#searchId option").length == 0) {
                $(".getSearchesLoad").show();
                $.getJSON("/user/favourite/get-search-list", function(data) {
                    $("#searchId").append(data).parent().show();
                    $(".getSearchesLoad").hide();
                });
            } else {
                $(".updateSel").toggle(function() {
                    if ($(this).is(':hidden'))
                        $(this).val('');
                });
            }
            return false;
        });

        $('#notificationCheck').click(function() {
            var div = $(this).parents().find('#notifications').toggle();

            if ($(this).is(':checked'))
                div.show();
            else
                div.hide();
        });

        $('#searchId').change(function() {
            if ($('#searchId option:selected').val() != "")
                $('#searchName').val($('#searchId option:selected').text());
        });

        $('.saveSearchShow').click(function() {
            var ssModal = $('#saveSearchModal');
            ssModal.find('.error').hide();
            ssModal.find("input[type='text']").val('');
            ssModal.find('#searchName').val($("#searchBar input[name='q']").val());
            //$('#searchId').val('').parent().hide();

            $('#saveSearchModal').modal('show');

            return false;
        });

        $('#saveSearchBtn').click(function() {
            $('#saveSearchModal .error').hide();

            if ($('#searchName').val() == '') {
                $("#saveSearchModal .error").show();
                return false;
            }

            /*if ($('.searchId:visible') && $('#searchId').val() != '' && $('#searchName').val() == $('#searchId option:selected').text()) { //update
             $.post('/user/favourite/search-update', $('#saveSearchForm').serialize(), function(data) {
             if (data.status == 1)
             $("#saveSearchAlert").addClass('alert-success');
             else
             $("#saveSearchAlert").addClass('alert-error');
             
             $("#saveSearchAlert span").text(data.message).parent().fadeIn();
             $("#saveSearchModal").modal('hide');
             btnLoading('reset', $('#saveSearchBtn'));
             });
             } else { // save new
             $.post('/user/favourite/search-save', $('#saveSearchForm').serialize(), function(data) {
             if (data.status == 1) {
             $('#searchId option:eq(0)').after('<option value="' + data.id + '">' + $("input[name='searchName']").val() + '</option>');
             $("#saveSearchAlert").addClass('alert-success');
             } else
             $("#saveSearchAlert").addClass('alert-error');
             
             $("#saveSearchAlert span").text(data.message).parent().fadeIn();
             $("#saveSearchModal").modal('hide');
             btnLoading('reset', $('#saveSearchBtn'));
             });
             }
             
             return false;*/
        });
    }


    /*************
     Advert submit
     *************/
    if ($('#printTextGen').length) {
        generateText();
    }

    $("#categoriesLevel1").change(function() {
        changeCategories(1);
    });

    $("#categoriesLevel0").change(function() {
        changeCategories(0);
    });

    $("#finalCat input").click(function() {
        $(this).parents('#finalCat').find('p.succ').removeClass('hide');
        var nextBtn = $("#nextStep .next");
        
        var nextUrl = '/oddaja/vpis-podatkov/' + $(this).val() + '/' + $("#categorieSelect select:last-child").val() + '//';

        if ($('#redirectTo').length)
            nextUrl += $('#redirectTo').val() + '/';
        else
            nextUrl += '/';

        var stId = nextBtn.data('stid');
        if(stId != '')
            nextUrl += stId;

        nextBtn.attr('href', nextUrl);
        nextBtn.show();
    });

    $(".mainRadio input[type='radio']").click(function() {
        $(this).parents(".mainRadio").find(".selected").removeClass("selected");
        $(this).parents('.span4').find('label:first').addClass('selected');
    });

    $(".mainRadio input[type='text']").click(function() {
        $(this).parents('.span4').find('input:first').click();
    });

    $(".priceSel input[type='radio']").click(function() {
        $(this).parents("#fieldset-price").find(".selected").removeClass("selected");
        $(this).parents('.priceSel').find('label:first').addClass('selected');
    });

    $(".priceSel input[type='text']").click(function() {
        $(this).parents('.priceSel').find('input:first').click();
    });

    $('#newContact').click(function() {
        var dialog = $('#newContactDialog');

        dialog.find('input[type!=hidden]').val('');
        dialog.find('input[name="contactCat"]').val(0);
        dialog.find('input[type="checkbox"]').attr('checked', false);

        dialog.find('.alert').hide();
        dialog.find('.error').removeClass('error');
        dialog.find('span[class!=label]').remove();

        btnLoading('reset', $('#contactSave'));

        $(this).keydown(function(event) {
            if (event.keyCode == 13) {
                event.preventDefault();
                return false;
            }
        });

        dialog.modal('show');
    });

    $("#contactSave").click(function() {
        btnLoading('loading', $(this));

        var dialog = $('#newContactDialog');

        if ($('#contactCat:checked').length > 0)
            dialog.find('input[name=contactCat]').val(1);

        var data = dialog.find('form').serialize();
        $.post('/user/contact/add-simple', data, function(res) {
            handleContactResponse(res);
            btnLoading('reset', $('#contactSave'));
        });
    });

    $('#changeCatBtn').click(function() { // change cat
        var dialog = $('#changeCategory');

        dialog.find('input[type=text]').val('');

        dialog.find('input').keydown(function(event) {
            if (event.keyCode == 13) {
                event.preventDefault();
                return false;
            }
        });

        dialog.modal('show');
    });

    $('#changeTypeBtn').click(function() { // change adv type
        $('#changeAdvertType').modal('show');
    });

    // Text dropdown
    $('.parent').change(function() {

        var ch = $(this).attr('class').split(" ");
        var par = $(this);

        //$(this).attr('disabled', 'disabled');
        for (var i = 0; i < ch.length; i++) {
            if (ch[i].indexOf('parent-') !== -1) {
                var childId = ch[i].substr(7);
                var childEl = $('select.child-' + childId);
                childEl.attr('disabled', 'disabled');

                childEl.find('option').remove().end();
                childEl.parent().find('input').val('');

                var attrVal = $(this).val();
                var options = '';

                childEl.each(function (key, value){
                    if (attrVal === "-1") {
                        $(value).parent().find('input, select').val('');
                        setChild($(value), options);
                        par.removeAttr('disabled');
                    }
                    else {
                        $.getJSON("/ogl/submit/get-child-values",
                                {
                                    valId: attrVal,
                                    child: $(value).attr('attributeid')
                                },
                        function(j) {
                            options = '';
                            for (var i = 0; i < j.length; i++) {
                                if (j[i].value === "-1")
                                    options = false;
                                else
                                    options += '<option value="' + j[i].value + '">' + j[i].display + '</option>';
                            }
                            $(value).parent().find('input').removeAttr('disabled');
                            setChild($(value), options);
                            par.removeAttr('disabled');
                        });
                    }
                });
            }
        }
    });


    $("#categorySpecific-osnovnipodatki-attr_1471").change(function() {
        nepremicnineHide();
    });
    nepremicnineHide();

    // hide child elements (travel cat)
    for (i in travelHideEl) {
        $('#categorySpecific-osnovno-attr_' + i).change(function() {
            travelHide($(this).attr('attributeid'));
        });
        travelHide(i);
    }

    // checkTextBox
    var tmpValues = {};
    $('.textCheckbox input:checkbox').change(function() {
        var textF = $(this).parent().children(':text');
        if ($(this).is(':checked')) {
            textF.val(tmpValues[ $(this).attr('id') ]);
            textF.show();
            textF.focus();
        }
        else {
            tmpValues[ $(this).attr('id') ] = textF.val();
            textF.val('');
            textF.hide();
        }
    });

    $('.textCheckbox input:checkbox').each(function() {
        if (!$(this).is(':checked'))
            $(this).parent().children(':text').hide();
    });

    // payment 
    if ($('#currentState').length) {
        $('#currentState').outerHeight($('#prices').outerHeight());
    }

    $("#advertSubmitType").change(function() {
        $(this).parent().find('button').prop('disabled', $(this).val() === "0");
    });


    /***********
     cPanel
     **********/
    $('.saa').click(function() {
        var res = true;

        if ($(this).hasClass('advDelete'))
            res = deletePrompt();

        id = $(this).attr('advertId');
        $('.checkAdvert').prop('checked', false);
        $('#advert_' + id).prop('checked', true);

        if (res == true)
            $('#' + $(this).attr('clickel')).click();
    });

    $("#addFounds input:radio").click(function() {
        $(this).parents('.content').find('.active').removeClass('active');
        $(this).parent('label').addClass('active');
    });

    $("#addFounds input:checked").click();

    $('.edit-adverts').click(function() {
        if ($('#advertList :checked').length < 1) {
            alert('Izberite vsaj en oglas');
            return false;
        }

        var res = true;
        if ($(this).hasClass('advDelete'))
            res = deletePrompt();

        if (res == true)
            $('#' + $(this).attr('clickel')).click();

        return false;
    });

    $('#deleteAdv').click(function(e) { // delete reason
        var reason = prompt('Vpišite razlog izbrisa oz. spremembe statusa');
        if (reason != null && $.trim(reason) != "") {
            $('input.deleteReason').val($.trim(reason));
        } else {
            if (reason != null)
                alert('Vpisati morate razlog.');

            e.preventDefault();
        }
    });

    // auto adv extend modal
    if ($('#autoExtendAdv').length > 0) {
        $('#advertList .advExtend').click(function() {
            var advId = $(this).attr('advertId');
            var modal = $('#autoExtendAdv');
            var form = modal.find('form');

            var advIdInput = modal.find('#advertId');
            if (advIdInput.val() == advId) {
                modal.modal('show');
                return false;
            }

            form[0].reset();
            advIdInput.val(advId);

            $.getJSON('/user/advert/auto-extend-data', {'advertId': advId}, function(res) {
                if (res != 0) {
                    if (res.dateTo != null) { // populate
                        modal.find('#dateTo').val(res.dateTo);

                        var pubCount = res.publications.length;
                        for (i = 0; i < pubCount; i++) {
                            modal.find('#publications-' + res.publications[i]).prop('checked', true);
                        }
                    }

                    for (key in res.prices) {
                        var input = modal.find('#pubPrice_' + key.replace('pub-', ''));
                        if (input.length > 0)
                            input.val(res.prices[key]);
                    }

                    var price = autoExtendPrice() + ' €';
                    modal.find('.price span.bulkText').hide();
                    form.find('.price span.priceVal').text(price).show();
                }

                modal.modal('show');
            });

            return false;
        });

        $('#autoExtendAdv #pubSelList .multiCheckbox input').click(function() {
            var price = autoExtendPrice() + ' €';
            $(this).parents('form').find('.price span.priceVal').text(price);
        });

        $('.advBatchExtend').click(function() {
            var checkedAdv = $('.checkItem:checked');
            var checkedAdvLen = checkedAdv.length;
            if (checkedAdvLen < 1) {
                alert('Izberite vsaj en oglas');
                return false;
            }

            var advIds = [];
            for(i = 0; i < checkedAdvLen; i++) {
                advIds.push($(checkedAdv[i]).val());
            }
            
            advIds = advIds.join(',');

            var modal = $('#autoExtendAdv');
            modal.find('#advertId').val(advIds);
            modal.find('.price span').hide();
            modal.find('.price span.bulkText').show();

            modal.modal('show');

            return false;
        });
    }

    var captchaRenewing = false;
    $('#refreshCaptcha').click(function() {
        if (captchaRenewing)
            return false;

        captchaRenewing = true;
        var button = $(this);
        var captcha = $('#captcha-input').parent();
        var captchaImage = captcha.find('img');

        btnLoading('loading', button);
        captchaImage.css('opacity', .5);

        $.getJSON('/user/message/refresh-captcha', function(data) {
            captchaImage.attr('src', data.src);
            captcha.find('#captcha-id').attr('value', data.id);

            btnLoading('reset', button);
            captchaRenewing = false;
            captchaImage.css('opacity', 1);
        }
        );

        return false;
    });

    /********
     Favourites
     *********/
    if ($('#favouriteSearchSett .notifyType').length > 0) {
        $('#favouriteSearchSett .notifyType').click(function() {
            $(this).parent().parent().find('div').toggle();
        });

        var dates = $("#dateStart, #dateEnd").datepicker({
            defaultDate: "+1w",
            regional: "sl",
            buttonImageOnly: false,
            buttonClass: 'hide'
        });
    }

    // stat
    if ($('#statistics #plot').length > 0 && typeof data != 'undefined') {
        $("#plot").bind("plothover", function(event, pos, item) {
            plotHover('tooltip', pos, item, true);
        });

        var optionsGrid = {
            xaxes: [{mode: 'time', timeformat: '%d.%m.'}],
            yaxes: [{min: 0},
                {alignTicksWithAxis: 1, position: 'right'}
            ],
            series: {
                lines: {show: true},
                points: {show: true}
            },
            legend: {container: $('#statLegend'), noColumns: 1},
            grid: {hoverable: true, clickable: true}
        }


        $.plot($("#plot"), data, optionsGrid);

        $('.showMore').click(function() {
            var line = $(this).attr('href').replace('#', '');
            $('#line-' + line).toggle();
        });
    }

    /**********
     Advert view stat
     ***********/
    if ($('#statModal #plot').length > 0) {
        $("#plot").bind("plothover", function(event, pos, item) {
            plotHover('tooltip', pos, item, true);
        });

        var optionsGrid = {
            xaxes: [{mode: 'time', timeformat: '%d.%m.'}],
            yaxes: [{min: 0},
                {alignTicksWithAxis: 1, position: 'right'}
            ],
            series: {
                lines: {show: true},
                points: {show: true}
            },
            legend: {container: $('#statLegend'), noColumns: 3},
            grid: {hoverable: true, clickable: true}
        }

        $('#showStat').click(function() {
            var id = $(this).attr('name').replace('#', '');
            $.post('/ogl/index/advert-stat', {'id': id}, function(res) {
                $.plot($("#plot"), res, optionsGrid);
            });
        });
    }

    /*********
     Category list
     *********/
    $.expr[":"].contains = $.expr.createPseudo(function(arg) {
        return function(elem) {
            return $(elem).text().toUpperCase().indexOf(arg.toUpperCase()) >= 0;
        };
    });

    $('.catList #catSearch').keyupDelay(filterCategories);

    $('.catList #resetList').click(function() {
        $('.catList #catSearch').val('');
        filterCategories();

        return false;
    });

    function filterCategories() {
        var val = $.trim($('.catList #catSearch').val());
        var articles = $('.catList article');

        articles.removeClass('resetMargin');
        articles.find('a').removeClass('active');

        if (val != '') { // filter
            articles.hide();
            articles.find('a:contains("' + val + '")').addClass('active').parents('article').show();
        } else { // reset
            articles.show();
        }

        var visible = $('.catList article:visible');
        for (i = 0; i < visible.length; i += 3) {
            $(visible[i]).addClass('resetMargin');
        }
    }

    $('#moreFilterValues .attrSearch').keyupDelay(filterAttributes);

    $('#moreFilterValues .resetList').click(function() {
        $('#moreFilterValues .tab-pane:visible .attrSearch').val('');
        filterAttributes();

        return false;
    });

    /* SHOP */
    $("#cartPP").click(function(e) {
        e.preventDefault();
        $("#pandpTabClick").click();

        $('body').animate({scrollTop: $("#pandpTabClick").offset().top - 100}, 400);
    });

    $("#samePostage").click(function() {
        checkPostage();
    });
    checkPostage();

    function filterAttributes() {
        var activeTab = $('#moreFilterValues .tab-pane:visible');
        var labels = activeTab.find('label');
        var val = $.trim(activeTab.find('.attrSearch').val());

        labels.removeClass('active');

        if (val != '') { // filter
            labels.hide();
            activeTab.find('label:contains("' + val + '")').addClass('active').show();
        } else { // reset
            labels.show();
        }
    }

    //contact map
    if ($('#profileMap').length > 0) {
        var latInput = $('#publish-locY');
        var lngInput = $('#publish-locX');

        if ($('#publish-hideMap:checked').length > 0) {
            $('#mapContainer').hide();
        }

        showMap('profileMap', lngInput.val(), latInput.val(), latInput, lngInput, 15);

        $('#publish-address').change(function() {
            if ($(this).parents().find($('#publish-hideMap:checked')).length > 0)
                return false;

            var address = $(this).val();

            if (address.length == 0) {
                alert('Vpišite lokacijo.')
                return false;
            }

            geocoder = new google.maps.Geocoder();
            geocoder.geocode({'address': address, 'region': 'SI'}, function(results, status) {
                if (status == google.maps.GeocoderStatus.OK) {
                    var location = results[0].geometry.location;
                    var locLat = location.lat(location);
                    var locLng = location.lng(location);
                    $('#publish-locY').val(locLat);
                    $('#publish-locX').val(locLng);

                    showMap('profileMap', locLng, locLat, latInput, lngInput, 15);
                } else {
                    showMap('profileMap', defaultLng, defaultLat, latInput, lngInput);
                    alert('Lokacija "' + address + '" ni najdena.');
                }
            });
        });

        $('#publish-hideMap').click(function() {
            if ($(this).is(':checked')) {
                $(this).parents().find('#mapContainer').hide();
                $(this).parents().find('#btnSearch').hide();
            }
            else {
                $(this).parents().find('#mapContainer').show();
                $(this).parents().find('#btnSearch').show();

                if (latInput.val().length > 0) {
                    showMap('profileMap', lngInput.val(), latInput.val(), latInput, lngInput, 15);
                }

                if ($('#publish-address').val().length > 0)
                    $('#publish-address').change();
            }
        });
    }

    /**
     * ARTICLES
     */
    if ($('#sms').length > 0) {
        $('a.advSubmit').click(function() {
            $('body').animate({scrollTop: $('h1.advSubmit').offset().top - 50}, 400);
            return false;
        });

        $('a.advExtend').click(function() {
            $('body').animate({scrollTop: $('h1.advExtend').offset().top - 50}, 400);
            return false;
        });
    }

    /** 
     * filter spare parts
     */
    if ($('.sparePartsFilter').length > 0) {
        var filterDiv = $('.sparePartsFilter');

        var menufModels = $.parseJSON(filterArr);
        var models = $.parseJSON(modelArr);
        // spare parts table
        var spTable = filterDiv.find('table tbody');
        var spTableOnPage = 15;

        filterDiv.find('select').change(function() {
            var filterName = $(this).attr('name');
            var val = $(this).val();

            switch (filterName) {
                case('manufacturer'):
                    var model = filterDiv.find('select[name="model"]');
                    model.find('option').remove();
                    model.append($("<option />").val('-1').text('-- Model --'));

                    if (val != '-1') {
                        var length = menufModels[val].length;
                        for (i = 0; i < length; i++) {
                            model.append($("<option />").val(menufModels[val][i]).text(menufModels[val][i]));
                        }
                    } else {
                        var length = models.length;
                        for (i = 0; i < length; i++) {
                            model.append($("<option />").val(models[i]).text(models[i]));
                        }
                    }
                    break;
            }

            var table = filterDiv.find('table tbody');
            table.find('tr').addClass('active').show();

            if (val != '-1')
                table.find('tr:not([data-' + filterName + '="' + $(this).val() + '"])').removeClass('active').hide();

            spTableNavigation(spTable, spTableOnPage);
        });

        function spTableNavigation(tableEl, onPage, action, toPage) {
            var tr = tableEl.find('tr.active');
            var firstVisible = tr.filter(':visible:first').index('tr.active');

            var count = tr.length;
            var pages = Math.ceil(count / onPage);

            switch (action) {
                case('next'):
                    var startFrom = firstVisible + onPage;
                    if (startFrom < count) {
                        tr.show();
                        tr.filter(':lt(' + startFrom + ')').hide();
                        tr.filter(':gt(' + (startFrom + onPage - 1) + ')').hide();
                    }
                    break;
                case('prev'):
                    var startFrom = firstVisible - onPage;
                    if (startFrom >= 0 && count > onPage) {
                        tr.show();
                        tr.filter(':lt(' + startFrom + ')').hide();
                        tr.filter(':gt(' + (startFrom + onPage - 1) + ')').hide();
                    }
                    break;
                case('page'):
                case('last'):
                    var startFrom = (toPage - 1) * onPage;
                    if (startFrom >= 0 && count > onPage && startFrom < count) {
                        tr.show();
                        tr.filter(':lt(' + startFrom + ')').hide();
                        tr.filter(':gt(' + (startFrom + onPage - 1) + ')').hide();
                    }
                    break;
                case('first'):
                default:
                    var startFrom = 0;
                    tr.show();
                    tr.filter(':gt(' + (onPage - 1) + ')').hide();
                    break;
            }

            var currPage = Math.ceil((startFrom + 1) / onPage);

            //navi 
            if (typeof (action) == "undefined") {
                var options = {
                    currentPage: currPage,
                    totalPages: Math.ceil(count / onPage),
                    numberOfPages: 5,
                    onPageClicked: function(e, originalEvent, type, page) {
                        spTableNavigation(tableEl, onPage, type, page);
                    }
                }

                $('#pagination').bootstrapPaginator(options);
            }
        }

        spTableNavigation(spTable, spTableOnPage);
    }

    if ($('#auction-win-msg').length > 0){
        $('#auction-win-msg').modal();
    }

});

function deletePrompt() {
    var reason = prompt('Vpišite razlog izbrisa oz. spremembe statusa');
    if (reason != null && reason != "") {
        $('input.deleteReason').val(reason);
        return true;
    } else {
        if (reason != null)
            alert('Vpisati morate razlog.');
    }

    return false;
}

function changeCategories(level) {

    //hide || remove all stuff
    $("#finalCat").addClass('hide');
    $("#finalCat input:radio").prop('checked', false);
    $("#finalCat .disabled").hide();
    $("#finalCat .succ").addClass('hide');
    $("#finalCat .error").addClass('hide');
    $("#nextStep .next").hide();

    var selText = $("#categoriesLevel" + level + " :selected").text();
    var selVal = $("#categoriesLevel" + level).val();
    $("#categorieSelect select:gt(" + (level - 1) + ")").remove();

    if (selText.indexOf('->') == -1) { // final node
        $("#selectedCategoryId").val(selVal);

        $("#categoriesLevel" + level).attr('name', 'cat');

        if ($("#categoriesLevel" + level + " option:selected").hasClass('hg')) // has gift
            $('#advertType_1 span').html('Prodam / Podarim');
        else
            $('#advertType_1 span').html('Prodam');

        if ($("#categoriesLevel" + level + " option:selected").hasClass('categoryContact')) {
            $("#categorieSelect select").attr('disabled', 'disabled');
            $.get("/ogl/submit/check-category-contact", {
                'catId': selVal
            }, function(data) {
                $("#categorieSelect select").removeAttr('disabled');
                if (data != 1) { // error
                    $("#finalCat .error").removeClass('hide');
                    $("#finalCat .disabled").show();
                }

                showEnd(level);
            });
        } else {
            showEnd(level);
        }

    } else { // still has subcategories
        $("#selectedCategoryPath").html('');
        $("#selectedCategoryId").val('');

        $("#categorieSelect").append('<select size="12" disabled="disabled" class="disabled"></select>');

        $("#categorieSelect select").attr('disabled', 'disabled');
        $.get("/ogl/submit/get-child-categories", {
            'parentId': selVal,
            'level': (level + 1)
        },
        function(data) {
            $("#categorieSelect select:gt(" + (level - 1) + ")").remove();
            $("#categorieSelect").append(data);
            $("#categoriesLevel" + (level + 1)).change(function() {
                changeCategories((level + 1));
            });
            $("#categorieSelect select").removeAttr('disabled');
        }
        );
    }
}

function showEnd(level) {
    var types = $("#categoriesLevel" + level + " :selected").attr('class').split(' ');

    $("#finalCat").removeClass('hide');
    showTypes(types, $("#categoriesLevel" + level).val(), '');
}

function showTypes(types) {
    $("#finalCat label").addClass('hide');

    for (i in types) {
        if (types[i].substr(0, 5) == 'type_') {
            $("#advertType_" + types[i].split('_')[1]).removeClass('hide');
        }
    }
}

function handleContactResponse(res) {

    if (res.status == "undefined")
        return;

    var msg = '';

    switch (res.status) {
        case('success'): // success
            $('#fieldset-contact').append(res.desc);

            var count = $('#fieldset-contact .span4').length;
            if (count % 3 == 1)
                $('#fieldset-contact .span4').last().addClass('clear-left');

            // bind selection
            $("#fieldset-contact input:last").parents('.select').click(function() {
                $(this).parents(".mainRadio").find(".selected").removeClass("selected");
                $(this).addClass('selected');
                $(this).find('input').prop('checked', true);
            });

            $('#fieldset-contact input:last').attr('checked', true).click();

            break;
        case('empty'): // all fields are empty
            msg = '<b>Napaka: </b>' + res.desc;
            break;
        case('error'): // input error
            msg = '<b>Napaka: </b> Preverite vpisana polja.';
            $.each(res.desc, function(id, value) {
                $.each(value, function(key, value) {
                    $('#newContactDialog label[for=' + id + ']').parent().addClass('error');
                    $('#newContactDialog #' + id).parent().append('<span class="help-inline">' + value + '</span>');
                });
            });

            break;
    }


    if (msg.length > 0) // show error
        $('#newContactDialog .alert').html(msg).fadeIn();
    else // close dialog
        $('#newContactDialog').modal('hide');
}

function setChild(childEl, options) {
    childEl.html(options).removeAttr('disabled');

    childEl.prepend('<option value="-1" label="--">--</option>');

    childEl.find('option:first').attr('selected', 'selected');
    childEl.change();
}

// buttonLoading 
function btnLoading(action, btn) {
    if (!btn.is('[data-orig-text]'))
        btn.attr('data-orig-text', btn.text());

    switch (action) {
        case 'loading':
            btn.text(btn.attr('data-loading-text'));
            btn.prop('disabled', true);
            break;
        case 'reset':
            btn.text(btn.attr('data-orig-text'));
            btn.prop('disabled', false);
            break;
    }
}

function generateText() {
    var text = new Array();
    var i = 0;

    //$('[sal-print=1]').each(
    $('input, select, checkbox, radio, fieldset').each(
            function(index) {
                if ($(this).attr('sal-print') !== '1')
                    return true;

                var pP = '';
                if ($(this).attr('sal-print-prefix') !== undefined)
                    pP = $(this).attr('sal-print-prefix') + ': ';

                if ($(this).prop("tagName") == 'INPUT') {
                    if ($(this).attr('type') == 'text') { //textbox
                        if ($(this).val() != '') {
                            val = $(this).val();

                            if ($(this).parents('.controls').find('.add-on').length > 0)
                                val += ' ' + $(this).parents('.controls').find('.add-on').text();

                            text[i++] = pP + val;
                        }
                    }
                }
                else if ($(this).prop("tagName") == 'FIELDSET') {
                    if ($(this).hasClass('checkboxlist')) {
                        $(this).find(':checked').each(function(inx) {
                            text[i++] = pP + jQuery.trim($(this).parent('label').text());
                        });
                    }
                    else {
                        text[i++] = pP + $(this).find(':checked').parent('label').text();
                    }
                }
                else if ($(this).prop("tagName") == 'SELECT') {
                    if ($(this).hasClass('combobox')) { // textdropdown
                        if ($(this).children(':selected').text() != '') // use select
                            text[i++] = pP + $(this).children(':selected').text();
                        else if ($(this).parents('.input-append').find("input:text").length > 0) { // use text input
                            if ($(this).parents('.input-append').find("input:text").val() != '')
                                text[i++] = pP + $(this).parents('.input-append').find("input:text").val();
                        }
                    }
                    else if ($(this).val() != "-1" && $(this).val() != null)
                        text[i++] = pP + $(this).children(':selected').text();
                }
            });

    if (text.length > 0)
        text[0] = '<b>' + text[0].toUpperCase() + '</b>';

    if ($("#descriptionPrint-printPrefix").val() != '')
        text[i++] = $('<div/>').text($("#descriptionPrint-printPrefix").val()).html();

    txt = jQuery.trim(text.join(', '));

    if (txt == '')
        txt = 'Ni besedila za tisk!';

    $("#printTextGen").html(txt);
}

/**********
 Gallery
 ***********/
function bigGalleryInit() {
    var youTubePlay = false;

    $('#galleryBigImg .modal-body').html($('#advGallery').html()); // copy html content
    $('#galleryBigImg .modal-body #showBig').remove();

    $("#galleryBigImg #smallImg").click(function() { // next pic
        var act = $("#galleryBigImg .thumbsList a.active");
        var imgCount = $("#galleryBigImg .thumbsList li").length;
        var nxtImg = $("#galleryBigImg .thumbsList a").index(act) + 1;

        if ((imgCount - 1) < nxtImg)
            nxtImg = 0;

        $("#galleryBigImg .thumbsList a")[nxtImg].click();
        return false;
    });

    $("#galleryBigImg .thumbsList a").click(function() { // navi click
        if ($(this).attr('video-id').length == 0) { // image
            $('#galleryBigImg .youTube').hide();
            $("#galleryBigImg .bigImg img").attr('src', $(this).attr('href'));
            $('#galleryBigImg .bigImg').show();

            if (youTubePlay) // stop yt
                $('#galleryBigImg .youTube').tubeplayer('pause');

        } else { // youtube
            youTubePlay = true;
            $('#galleryBigImg .bigImg').hide();
            $('#galleryBigImg .youTube').show();
            $('#galleryBigImg .youTube').tubeplayer('play', $(this).attr('video-id'));
        }

        $("#galleryBigImg .thumbsList a.active").removeClass('active');
        $(this).addClass('active');

        return false;
    });

    $("#galleryBigImg .thumbsList .notice").click(function() {
        $(this).parents('li').find('a').click();
    });

    $("#galleryBigImg .prev, #galleryBigImg .next").click(function() {
        //thumbsList
        var thumbL = $("#galleryBigImg .thumbsList ul");
        var curMargin = parseInt(thumbL.css('margin-left'));

        var mL = 69 * 2;
        var imgCount = $("#galleryBigImg .thumbsList li").length;
        var limit = Math.ceil((imgCount - 11) / 2) * mL * -1;

        if ($(this).hasClass("next")) {
            newMargin = curMargin - mL;
            if (newMargin < limit)
                return false;
        } else {
            newMargin = curMargin + mL;
            if (newMargin > 0)
                return false;
        }

        thumbL.animate({marginLeft: newMargin + "px"}, 400);

        return false;
    });

    if ($('#galleryBigImg .thumbsList .yt').length > 0) { // yt init
        var id = $('#galleryBigImg .thumbsList .yt').find('a').attr('video-id');
        $('#galleryBigImg .youTube').tubeplayer({
            width: 760,
            height: 570,
            allowFullScreen: 'false',
            initialVideo: id
        });
    }

    $('#galleryBigImg').on('hide', function() { // stop youtube if playing
        if (youTubePlay)
            $('#galleryBigImg .youTube').tubeplayer('pause');
    });
}

/********
 Recommend to friend
 ********/
if ($('#shareEmail').length > 0) {
    $('.shareIco').tooltip();

    $('#shareEmail').click(function() {
        $('#recommendToFriend').modal('show');
        return false;
    });

    $('#printAdv').click(function() {
        window.print();
        return false;
    });
}

/*****
 Cookies
 *****/

function enableIpromCookie() {
    var i = document.createElement('IMG');
    i.src = '//adserver.iprom.net/adserver7/Consent?consent=1&t=' + (new Date()).getTime();
    i.width = 1;
    i.height = 1;
    i.style.position = 'absolute';
    i.style.left = '-10000px';
    if (document.body != null) {
        document.body.appendChild(i);
    }
}

function disableIpromCookie() {
    var i = document.createElement('IMG');
    i.src = '//adserver.iprom.net/adserver7/Consent?consent=0&t=' + (new Date()).getTime();
    i.width = 1;
    i.height = 1;
    i.style.position = 'absolute';
    i.style.left = '-10000px';
    if (document.body != null) {
        document.body.appendChild(i);
    }
}

function nepremicnineHide() {
    val = $("#categorySpecific-osnovnipodatki-attr_1471").val();

    if (val === "83851" || val === "83852" || val === "-1") {
        $("#categorySpecific-osnovnipodatki-attr_1472").parents('.control-group').show();
        $("#categorySpecific-osnovnipodatki-attr_1473").parents('.control-group').show();
        $("#categorySpecific-osnovnipodatki-attr_1474").parents('.control-group').show();
    }
    else {
        $("#categorySpecific-osnovnipodatki-attr_1472").parents('.control-group').hide();
        $("#categorySpecific-osnovnipodatki-attr_1473").parents('.control-group').hide();
        $("#categorySpecific-osnovnipodatki-attr_1474").parents('.control-group').hide();
    }
}

// tourism
var travelHideEl = {
    1448: {values: [67116], childId: 1493},
    1496: {values: [147774], childId: 1497},
    1503: {values: [147795], childId: 1504},
    1505: {values: [147813, 147814, 147815, 147816], childId: 1506},
    1489:{ values:[147723, 147724], childId:1490 },
};

function travelHide(attrId) {
    attrId = parseInt(attrId);

    if (attrId in travelHideEl) {
        var val = parseInt($("#categorySpecific-osnovno-attr_" + attrId).val());
        var attr = travelHideEl[attrId];
        if (val === -1 || $.inArray(val, attr.values) > -1) { // show child
            $("#categorySpecific-osnovno-attr_" + attr.childId).parents('.control-group').show();
        }
        else { // hide child
            $("#categorySpecific-osnovno-attr_" + attr.childId).parents('.control-group').hide();
        }
    }
}

function checkPostage() {
    if ($("#samePostage").is(':checked')) {
        $("#postHide").show();
    }
    else {
        $("#postHide").hide();
    }
}

var defaultLat = 46.055379;
var defaultLng = 14.511677;
function showMap(id, lng, lat, latInput, lngInput, zoom, coordsLenght, draggable) {
    lat = (lat) ? parseLocalNum(lat) : defaultLat;
    lng = (lng) ? parseLocalNum(lng) : defaultLng;
    if (typeof zoom == 'undefined' || zoom == '')
        zoom = 11;
    if (typeof coordsLenght == 'undefined' || coordsLenght == '')
        coordsLenght = 6;
    if (typeof draggable == 'undefined' || draggable == '')
        draggable = true;

    var map = new google.maps.Map(document.getElementById(id), {
        zoom: zoom,
        center: new google.maps.LatLng(lat, lng),
        mapTypeId: google.maps.MapTypeId.ROADMAP
    });

    var myMarker = new google.maps.Marker({
        position: new google.maps.LatLng(lat, lng),
        draggable: draggable
    });

    if (typeof latInput != 'undefined' && typeof lngInput != 'undefined') {
        google.maps.event.addListener(myMarker, 'dragend', function(evt) {
            lngInput.val(evt.latLng.lng().toFixed(coordsLenght));
            latInput.val(evt.latLng.lat().toFixed(coordsLenght));

        });
    }

    map.setCenter(myMarker.position);
    myMarker.setMap(map);
}

function parseLocalNum(num) {
    if (typeof num == 'number')
        num = num.toString();

    return parseFloat(num.replace(",", "."));
}

// autoExtend advert
function autoExtendPrice() {
    var form = $('#autoExtendAdv form');

    var price = 0;
    var priceList = form.find('#priceList input').serializeObject();

    var selPubs = form.find('#pubSelList input:checked');
    var selPubsCount = selPubs.length;

    if (selPubsCount == 0) { // charge web
        return price = (parseFloat(priceList.pubPrice_1)).toFixed(2);
    }

    for (i = 0; i < selPubsCount; i++) { // charge print
        var pubId = $(selPubs[i]).val();
        price += parseFloat(priceList['pubPrice_' + pubId]);
    }

    return price.toFixed(2);
}

function touchSupported() {
    if (document && document.createEvent) {
        try {
            document.createEvent('TouchEvent');
            return true;
        } catch (e) {
            return false;
        }
    }
}

function changeRss(){
    var clanek = $('div.clanekRss:visible');

    if (clanek.size() > 0){
        clanek.hide();

        if(clanek.next().size() > 0)
            next = clanek.next();
        else
            next = $('div.clanekRss').first();

        next.show();
    }
}

   